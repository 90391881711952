.threejs {
    height: 100%;
    width: 100%;
}
.threejs-container {
    height: 100%;
    overflow: hidden;
}
.threejs-react-container {
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}
